import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrentAccountGQL, CurrentAccountQuery } from "../graphql/gql";

/**
 * Service for managing current account data.
 */
@Injectable({
  providedIn: 'root'
})
export class CurrentAccountService {
  private currentAccountSubject:
    BehaviorSubject<CurrentAccountQuery | null> = new BehaviorSubject<CurrentAccountQuery | null>(null);
  public currentAccount = this.currentAccountSubject.asObservable();

  constructor(private currentAccountGQL: CurrentAccountGQL) {
    this.loadInitialData();
  }

  loadInitialData() {
    this.currentAccountGQL.fetch({}, { errorPolicy: 'all' }).pipe(
      map(result => result.data)
    ).subscribe(account => this.currentAccountSubject.next
    (account));
  }

  /**
   * Updates the current account with the provided account object.
   *
   * @param {CurrentAccountQuery} account - The updated account object.
   */
  updateCurrentAccount(account: CurrentAccountQuery) {
    this.currentAccountSubject.next(account);
  }

  /**
   * Retrieves the current account.
   *
   * @returns {Observable<CurrentAccountQuery>} An observable that emits the current account information.
   */
  getCurrentAccount(): Observable<CurrentAccountQuery> {
    return this.currentAccountGQL.fetch({}, {errorPolicy: 'all'}).pipe(
      map(result => result.data)
    );
  }
}
