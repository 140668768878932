import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrentUserGQL, CurrentUserQuery } from "../graphql/gql";

/**
 * Service for managing the current user's data.
 */
@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  private currentUserSubject = new BehaviorSubject<CurrentUserQuery | null>(null);
  public currentUser = this.currentUserSubject.asObservable();

  constructor(private currentUserGQL: CurrentUserGQL) {
    this.loadInitialData();
  }

  loadInitialData() {
    this.currentUserGQL.fetch({}, { errorPolicy: 'all' }).pipe(
      map(result => result.data)
    ).subscribe(user => this.currentUserSubject.next
    (user));
  }

  /**
   * Updates the current user data.
   *
   * @param {CurrentUserQuery} user - The updated current user data.
   * @return {void}
   */
  updateCurrentUser(user: CurrentUserQuery): void {
    this.currentUserSubject.next(user);
  }

  /**
   * Retrieves the current user through an observable.
   *
   * @return {Observable<CurrentUserQuery>} Observable that emits the current user information.
   */
  getCurrentUser(): Observable<CurrentUserQuery> {
    return this.currentUserGQL.fetch({}, {errorPolicy: 'all'}).pipe(
      map(result => result.data)
    );
  }

  /**
   * Logs out the current user.
   * This method resets the currentUserSubject to null.
   *
   * @returns {void}
   */
  logout(): void {
    this.currentUserSubject.next(null);
  }
}
