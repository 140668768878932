import { Component, OnInit } from '@angular/core';
import { ToastService } from './toast/toast.service';
import { CurrentAccount } from "./interfaces/current-account.interface";
import { CurrentUser } from "./interfaces/current-user.interface";
import { CurrentAccountService } from "./services/current-account.service";
import { CurrentUserService } from "./services/current-user.service";
import { switchMap } from "rxjs";
import { tap } from "rxjs/operators";
import { BrandingService } from "./services/branding.service";

/**
 * The AppComponent class represents the root component of the application.
 *
 * The component is responsible for initializing the application, subscribing to changes in the user and account data,
 * and applying the appropriate branding based on the current user or the hosting environment.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  currentUser: CurrentUser | null | undefined = null;
  currentAccount: CurrentAccount | null | undefined = null;
  constructor(
    private brandingService: BrandingService,
    private currentUserService: CurrentUserService,
    private currentAccountService: CurrentAccountService,
    private toastService: ToastService) {}

  /**
   * Performs initialization tasks when the component is initialized.
   *
   * @returns {void}
   */
  ngOnInit(): void {
    this.currentUserService.currentUser.pipe(
      tap(result => this.currentUser = result?.currentUser),
      switchMap(() => {
        return this.currentAccountService.currentAccount;
      })
    ).subscribe(accountData => {
      this.currentAccount = accountData?.currentAccount;
      if (this.currentAccount) {
        this.applyTheme(this.currentAccount.branding.colorTheme);
      } else {
        this.brandingService.getBrandingByHost(window.location.host)
          .subscribe(({ brandingByHost }) => {
            if (brandingByHost) {
              this.applyTheme(brandingByHost.colorTheme);
            }
        });
      }
    });

    this.toastService.initLegacyFlashMessageHandling()
  }

  /**
   * Applies the given theme to the root element of the document.
   *
   * @param {object} theme - The theme object containing key-value pairs for the theme properties.
   * @returns {void}
   */
  applyTheme(theme: any): void {
    const rootElement = document.querySelector(':root') as HTMLElement;
    const themeKeys = Object.keys(theme);

    themeKeys.forEach(key => {
      const formattedKey = `--brandshelter-${key.replace(/([A-Z])/g, "-$1").toLowerCase()}`;
      if (theme[key]) {
        rootElement.style.setProperty(formattedKey, theme[key]);
      }
    });
  }
}
