<div class="app-paginated-table-container">
  <p-table #pTable
    [columns]="columns"
    [value]="value"
    [lazy]="true"
    (onLazyLoad)="onLazyLoad($event)"
    [loading]="isLoading"
    [paginator]="true"
    [rows]="pageSize"
    [rowsPerPageOptions]="pageSizeOptions"
    [totalRecords]="totalItemCount"
    [resizableColumns]="true"
    columnResizeMode="expand"
    [reorderableColumns]="true"
    [tableStyle]="{ 'width': '100%', 'table-layout': 'fixed' }"
    styleClass="p-datatable-sm"
    [totalRecords]="totalRecords"
    selectionMode="multiple"
    [selection]="selection"
    (selectionChange)="onSelectionChange($event)"
    [selectAll]="selectAll"
    (selectAllChange)="onSelectAllChange($event)"
  >
    <!-- <ng-template pTemplate="caption">
      <div class="flex">
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" #testInput1 (input)="pTable.filter(testInput1.value, 'field1', 'contains')" />
          <input pInputText type="text" #testInput2 (input)="pTable.filter(testInput2.value, 'field1', 'contains2')" />
        </span>
      </div>
    </ng-template> -->
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 4rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th
          [pSortableColumn]="column.columnDef.orderKey"
          [pSortableColumnDisabled]="!column.columnDef.orderKey"
          pResizableColumn
          pReorderableColumn
          *ngFor="let column of getVisibleColumns()"
        >
          <div class="header-cell">
            <div class="header-cell-title">{{ column.columnDef.label }}</div>
            <p-sortIcon *ngIf="column.columnDef.orderKey" [field]="column.columnDef.orderKey"></p-sortIcon>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-columns="columns">
      <tr (click)="onRowClick(item)">
        <td>
          <p-tableCheckbox [value]="item"></p-tableCheckbox>
        </td>
        <td *ngFor="let column of getVisibleColumns()">
          <ng-container *ngIf="column.columnDef.template || column.columnDef.cellType; else defaultCellContent">
            <ng-container *ngTemplateOutlet="getTemplate(column.columnDef); context:{item: item, column: column.columnDef}"></ng-container>
          </ng-container>
          <ng-template #defaultCellContent>
            {{ dig(item, column.columnDef.attr) }}
          </ng-template>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <!-- This empty tag is needed to center the pagination bar -->
    </ng-template>
    <ng-template pTemplate="paginatorright">
      <div class="table-settings-icon" (click)="onSettingsIconClick($event)">&#9881;</div>
    </ng-template>
  </p-table>

  <ng-template #dateCellTemplate let-item="item" let-column="column">
    {{ dig(item, column.attr) | date }}
  </ng-template>

  <ng-template #booleanCellTemplate let-item="item" let-column="column">
    {{ dig(item, column.attr) ? '&check;' : '&#65794;' }}
  </ng-template>

  <ng-template #tableSettingsTemplate>
    <div class="table-settings-container">
      <h5>Show Columns</h5>
      <div class="show-columns-container">
        <ul>
          <li *ngFor="let column of columns">
            <p-checkbox [label]="column.columnDef.label" [binary]="true" [(ngModel)]="column.visible"></p-checkbox>
          </li>
        </ul>
      </div>
    </div>
  </ng-template>
</div>