import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AlertModule } from 'ngx-bootstrap/alert';

import { AppComponent } from './app.component';
import { LegacyAppComponent } from './legacy-app/legacy-app.component';
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { GraphQLModule } from './graphql.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DomainsComponent } from './admin/domains/domains.component';
import { UsersComponent } from './ccp/users/users.component';
import { AccountsComponent } from './ccp/accounts/accounts.component';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { DEFAULT_DIALOG_CONFIG, DialogModule } from '@angular/cdk/dialog';
import { DialogContainerComponent } from './dialog-container/dialog-container.component';
import { ToastrModule } from 'ngx-toastr';
import { PaginatedTableModule } from './paginated-table/paginated-table.module';
import { LogbookEntriesComponent } from "./admin/logbook-entries/logbook-entries.component";
import { HeaderComponent } from "./header/header.component";
import { TokenInterceptor } from "./interceptors/token.interceptor";
import { FooterComponent } from "./footer/footer.component";
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    DomainsComponent,
    UsersComponent,
    AccountsComponent,
    LegacyAppComponent,
    LogbookEntriesComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    GraphQLModule,
    HttpClientModule,
    PasswordModule,
    InputTextModule,
    DialogModule,
    ToastrModule.forRoot({ closeButton: true, timeOut: 8000 }),
    PaginatedTableModule,
    HeaderComponent,
    FooterComponent,
    ToolbarModule,
    ButtonModule
  ],
  providers: [
    { provide: DEFAULT_DIALOG_CONFIG, useValue: { container: DialogContainerComponent }},
    // Deactivated for now
    // { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
