import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';
import { CountryCode } from 'src/app/graphql/gql';
import { AuthTokenService } from '../services/auth-token.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  form!: FormGroup;
  submitted = false;
  authenticityToken!: string;
  public countryCodes = Object.values(CountryCode);
  public countryStates = [{id: 0, name: 'Other'}];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authTokenService: AuthTokenService
  ) { }

  async ngOnInit() {
    this.form = this.formBuilder.group({

      password: ['', Validators.compose([Validators.required, Validators.minLength(12), Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{4,}$")])],
      password_confirmation: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      company: ['', Validators.required],
      title: [''],
      street: ['', Validators.required],
      zip: ['', Validators.required],
      city: ['', Validators.required],
      country: [''],
      country_state: [''],
      phone: ['', Validators.required],
      cell_phone: [''],
      commercial_register_number: [''],
      id_card_number: [''],
      tax_number_company: [''],
      tax_number_personal: [''],
      passphrase: ['', Validators.required],
      answer: ['', Validators.required],
      accept_dpa: [''],
      accept_privacy_policy: ['']
    });
    this.form.addValidators(this.matchValidator('password', 'password_confirmation'));
    this.authenticityToken = await this.authTokenService.getAuthenticityToken(true)
  }


  matchValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (abstractControl: AbstractControl) => {
        const control = abstractControl.get(controlName);
        const matchingControl = abstractControl.get(matchingControlName);

        if (matchingControl!.errors && !matchingControl!.errors?.['confirmedValidator']) {
            return null;
        }

        if (control!.value !== matchingControl!.value) {
          const error = { confirmedValidator: 'Passwords do not match.' };
          matchingControl!.setErrors(error);
          return error;
        } else {
          matchingControl!.setErrors(null);
          return null;
        }
    }
  }

  async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      console.log('INVALID')
      return;
    }

    let body = encodeURIComponent("user[password]") + "=" + encodeURIComponent(this.form.value.password) + "&" +
               encodeURIComponent("user[password_confirmation]") + "=" + encodeURIComponent(this.form.value.password_confirmation) + "&" +
               encodeURIComponent("user[firstname]") + "=" + encodeURIComponent(this.form.value.firstname) + "&" +
               encodeURIComponent("user[lastname]") + "=" + encodeURIComponent(this.form.value.lastname) + "&" +
               encodeURIComponent("user[email]") + "=" + encodeURIComponent(this.form.value.email) + "&" +
               encodeURIComponent("user[company]") + "=" + encodeURIComponent(this.form.value.company) + "&" +
               encodeURIComponent("user[title]") + "=" + encodeURIComponent(this.form.value.title) + "&" +
               encodeURIComponent("user[street]") + "=" + encodeURIComponent(this.form.value.street) + "&" +
               encodeURIComponent("user[zip]") + "=" + encodeURIComponent(this.form.value.zip) + "&" +
               encodeURIComponent("user[city]") + "=" + encodeURIComponent(this.form.value.city) + "&" +
               encodeURIComponent("user[country]") + "=" + encodeURIComponent(this.form.value.country) + "&" +
               encodeURIComponent("user[country_state]") + "=" + encodeURIComponent(this.form.value.country_state) + "&" +
               encodeURIComponent("user[phone]") + "=" + encodeURIComponent(this.form.value.phone) + "&" +
               encodeURIComponent("user[cell_phone]") + "=" + encodeURIComponent(this.form.value.cell_phone) + "&" +
               encodeURIComponent("user[commercial_register_number]") + "=" + encodeURIComponent(this.form.value.commercial_register_number) + "&" +
               encodeURIComponent("user[id_card_number]") + "=" + encodeURIComponent(this.form.value.id_card_number) + "&" +
               encodeURIComponent("user[tax_number_company]") + "=" + encodeURIComponent(this.form.value.tax_number_company) + "&" +
               encodeURIComponent("user[tax_number_personal]") + "=" + encodeURIComponent(this.form.value.tax_number_personal) + "&" +
               encodeURIComponent("user[passphrase]") + "=" + encodeURIComponent(this.form.value.passphrase) + "&" +
               encodeURIComponent("user[answer]") + "=" + encodeURIComponent(this.form.value.answer) + "&" +
               encodeURIComponent("user[accept_dpa]") + "=" + encodeURIComponent(this.form.value.accept_dpa) + "&" +
               encodeURIComponent("user[accept_privacy_policy]") + "=" + encodeURIComponent(this.form.value.accept_privacy_policy)

    let response = await fetch(environment.legacyAppUrl + "/accounts/create_frontend", {
      "headers": {
        "accept": "application/json",
        "content-type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": this.authenticityToken
      },
      "body": body,
      "method": "POST",
      "credentials": 'include'
    });

    console.log(response)

    this.handleResponse(response)
  }

  private async handleResponse(response: Response) {
    if(response.status == 201) {
      this.router.navigateByUrl("/")
    } else if(response.status == 200) {
      this.form.reset()
      this.submitted = false
    }
  }

  async fetchCountryStates(country: any) {
    let response = await fetch(environment.legacyAppUrl + `/countries/${country.target.value}/states`, {
      "headers": {
        "accept": "application/json",
        "X-CSRF-Token": await this.authTokenService.getAuthenticityToken(true)
      },
      "method": "GET",
    })
    const response_json = await response.json()
    this.countryStates = response_json
  }
}
