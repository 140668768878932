import { ArrayDataSource, DataSource } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { ApolloError } from '@apollo/client';
import { Apollo } from 'apollo-angular';
import { GraphQLError } from 'graphql';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrentUserGQL, CurrentUserQuery, AccountsGQL } from 'src/app/graphql/gql';
import { ColumnDefinition } from 'src/app/paginated-table/paginated-table.component';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})
export class AccountsComponent {

  loading = true;
  data!: CurrentUserQuery;
  errors: readonly GraphQLError[] | undefined;

  columns: ColumnDefinition[] = [
    {
      attr: 'clientNumber',
      label: 'Client Number',
      orderKey: 'clientNumber'
    },
    {
      attr: 'company',
      label: 'Company',
      orderKey: 'company'
    },
    {
      attr: 'state',
      label: 'State'
    }
  ]
 
  constructor(private apollo: Apollo, public accountsGQL: AccountsGQL) {}

  dataMapper(response: any): any {
    return response.data.accounts;
  }

  onAccountClick(account: any) {
    console.log(account)
  }
}
