import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";

import { SignupComponent } from './signup/signup.component';
import { UsersComponent } from './ccp/users/users.component';
import { AccountsComponent } from './ccp/accounts/accounts.component';
import { DomainsComponent } from './admin/domains/domains.component';
import { LegacyAppComponent } from "./legacy-app/legacy-app.component";
import { LoginComponent } from './login/login.component';
import { LogbookEntriesComponent } from "./admin/logbook-entries/logbook-entries.component";

/**
 * @property {string} path - The path for the route.
 * @property {Component} component - The component to be rendered for the route.
 */
const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'ccp/users', component: UsersComponent },
  { path: 'ccp/accounts', component: AccountsComponent },
  { path: 'access_tokens', component: LegacyAppComponent },
  { path: 'admin/accounts', component: LegacyAppComponent },
  { path: 'admin/aggregated_stats', component: LegacyAppComponent },
  { path: 'admin/balances', component: LegacyAppComponent },
  { path: 'admin/billing/bulk_price_changes/new', component: LegacyAppComponent },
  { path: 'admin/billing/debtors', component: LegacyAppComponent },
  { path: 'admin/billing/recurring_billings', component: LegacyAppComponent },
  { path: 'admin/contacts', component: LegacyAppComponent },
  { path: 'admin/dns_keys', component: LegacyAppComponent },
  { path: 'admin/domains', component: DomainsComponent },
  { path: 'admin/events', component: LegacyAppComponent },
  { path: 'admin/invoices', component: LegacyAppComponent },
  { path: 'admin/invoice_items', component: LegacyAppComponent },
  { path: 'admin/logbook/entries', component: LogbookEntriesComponent },
  { path: 'admin/news', component: LegacyAppComponent },
  { path: 'admin/ssl_certificates', component: LegacyAppComponent },
  { path: 'admin/sso_configs', component: LegacyAppComponent },
  { path: 'admin/tan_sets', component: LegacyAppComponent },
  { path: 'admin/work_requests', component: LegacyAppComponent },
  { path: 'admin/users', component: LegacyAppComponent },
  { path: 'admin/user_categories', component: LegacyAppComponent },
  { path: 'admin/user_sessions', component: LegacyAppComponent },
  { path: 'approval/procedures', component: LegacyAppComponent },
  { path: 'bank_accounts', component: LegacyAppComponent },
  { path: 'billing_configs', component: LegacyAppComponent },
  { path: 'brand_monitorings/applicant_watch/new', component: LegacyAppComponent },
  { path: 'brand_monitorings/application_status_monitor/new', component: LegacyAppComponent },
  { path: 'brand_monitorings/common_law_watch/new', component: LegacyAppComponent },
  { path: 'brand_monitorings/company_name_watch/new', component: LegacyAppComponent },
  { path: 'brand_monitorings/design_watch/new', component: LegacyAppComponent },
  { path: 'brand_monitorings/goods_class_watch/new', component: LegacyAppComponent },
  { path: 'brand_monitorings/internet_content_watch/new', component: LegacyAppComponent },
  { path: 'brand_monitorings/marketplace_watch/new', component: LegacyAppComponent },
  { path: 'brand_monitorings/mobile_apps_watch/new', component: LegacyAppComponent },
  { path: 'brand_monitorings/official_gazette_watch/new', component: LegacyAppComponent },
  { path: 'brand_monitorings/online_logo_watch/new', component: LegacyAppComponent },
  { path: 'brand_monitorings/ownership_watch/new', component: LegacyAppComponent },
  { path: 'brand_monitorings/social_media_watch/new', component: LegacyAppComponent },
  { path: 'brand_monitorings/trademark_watch/new', component: LegacyAppComponent },
  { path: 'brand_monitorings/trademark_early_warning_watch/new', component: LegacyAppComponent },
  { path: 'brand_monitorings/webpage_change_watch/new', component: LegacyAppComponent },
  { path: 'brand_services/new', component: LegacyAppComponent },
  { path: 'branding_settings', component: LegacyAppComponent },
  { path: 'brands', component: LegacyAppComponent },
  { path: 'cart', component: LegacyAppComponent },
  { path: 'contacts', component: LegacyAppComponent },
  { path: 'contact_sets', component: LegacyAppComponent },
  { path: 'credit_cards', component: LegacyAppComponent },
  { path: 'custom_sessions', component: LegacyAppComponent },
  { path: 'documents', component: LegacyAppComponent },
  { path: 'domain_groups', component: LegacyAppComponent },
  { path: 'domains', component: LegacyAppComponent },
  { path: 'domains/check/new', component: LegacyAppComponent },
  { path: 'domains/externals/new', component: LegacyAppComponent },
  { path: 'domains/transfers/new', component: LegacyAppComponent },
  { path: 'dns/resource_records', component: LegacyAppComponent },
  { path: 'logbook/entries', component: LegacyAppComponent },
  { path: 'invoices', component: LegacyAppComponent },
  { path: 'monitorings', component: LegacyAppComponent },
  { path: 'nameservers', component: LegacyAppComponent },
  { path: 'nameserver_sets', component: LegacyAppComponent },
  { path: 'news', component: LegacyAppComponent },
  { path: 'notes', component: LegacyAppComponent },
  { path: 'personal_data/edit', component: LegacyAppComponent },
  { path: 'prices', component: LegacyAppComponent },
  { path: 'recurring_billings', component: LegacyAppComponent },
  { path: 'services/whois/new', component: LegacyAppComponent },
  { path: 'services/dig/new', component: LegacyAppComponent },
  { path: 'services/nameserver_check/new', component: LegacyAppComponent },
  { path: 'services/idn_punycode_translation', component: LegacyAppComponent },
  { path: 'services/zone_import/new', component: LegacyAppComponent },
  { path: 'services/ds_records/new', component: LegacyAppComponent },
  { path: 'settings', component: LegacyAppComponent },
  { path: 'social_media_accounts', component: LegacyAppComponent },
  { path: 'ssl_certificates', component: LegacyAppComponent },
  { path: 'subusers', component: LegacyAppComponent },
  { path: 'tld_groups', component: LegacyAppComponent },
  { path: 'trademarks', component: LegacyAppComponent },
  { path: 'admin/domains', component: LegacyAppComponent },
  { path: '', component: LegacyAppComponent },
  { path: '**', component: LegacyAppComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
