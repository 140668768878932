import { Component } from '@angular/core';
import { CurrentUserQuery, LogbookEntriesGQL } from '../../graphql/gql';
import { ColumnDefinition } from 'src/app/paginated-table/paginated-table.component';
import { GraphQLError } from "graphql/index";

/**
 * Represents the LogbookEntriesComponent class.
 *
 * @class
 */
@Component({
  selector: 'app-logbook-entries',
  templateUrl: './logbook-entries.component.html',
  styleUrls: ['./logbook-entries.component.css']
})

export class LogbookEntriesComponent {
  loading = true;
  data!: CurrentUserQuery;
  errors: readonly GraphQLError[] | undefined;

  /**
   * A data structure storing attribute name, label and sorting order for each column.
   */
  columns: ColumnDefinition[] = [
    {
      attr: 'createdAt',
      label: 'Created at',
      orderKey: 'createdAt'
    },
    {
      attr: 'event',
      label: 'Event',
      orderKey: 'event'
    },
    {
      attr: 'thingType',
      label: 'Object',
      orderKey: 'thingType'
    },
    {
      attr: 'objectName',
      label: 'Object name',
      orderKey: 'objectName'
    },
    {
      attr: 'ipAddress',
      label: 'IP address',
      orderKey: 'ipAddress'
    },
    {
      attr: 'owner.clientNumber',
      label: 'Account: Client number',
      orderKey: 'clientNumber'
    },
    {
      attr: 'owner.company',
      label: 'Account: Organization',
      orderKey: 'company'
    }
  ]

  constructor(public logbookEntriesGQL: LogbookEntriesGQL) {}

  /**
   * Processes the provided response data and extracts the logbook entries.
   *
   * @param {any} response - The response data to be processed.
   * @return {any} - The logbook entries extracted from the response data.
   */
  dataMapper(response: any): any {
    console.log(response.data.logbookEntries);
    return response.data.logbookEntries;
  }

  /**
   * Handles the click event of a logbook entry.
   *
   * @param {any} logbookEntry - The logbook entry associated with the click event.
   *
   * @return {void}
   */
  onLogbookEntryClick(logbookEntry: any): void {
    console.log(logbookEntry)
  }
}
