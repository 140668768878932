<div class="login-card card">
  <div class="header mb-3">
    <img alt="Logo" src="/assets/images/logo.png">
    <a class="text-white" [routerLink]="['/signup']">Register a new account</a>
  </div>

  <ng-container *ngIf="!tanFormVisible; else tanFormTemplate">
    <alert type="warning" [hidden]="!invalidUserOrPassword" i18n>Invalid Username or password.</alert>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="mb-3">
        <label class="form-label" i18n>Username</label>
        <input type="text" formControlName="username" name="user[login]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('username')?.errors }" />
        <div *ngIf="submitted && form.get('username')?.errors">
          <div *ngIf="form.get('username')?.errors?.['required']">Username is required</div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label" i18n="label|Password in login form">Password</label>
        <input type="password" formControlName="password" name="user[password]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('password')?.errors }" />
        <div *ngIf="submitted && form.get('password')?.errors">
          <div *ngIf="form.get('password')?.errors?.['required']">Password is required</div>
        </div>
      </div>
      <div class="form-check mb-3">
        <input class="form-check-input" formControlName="remember_me" type="checkbox" name="user[remember_me]" value="" id="flexCheckDefault">
        <label class="form-check-label" for="flexCheckDefault">Remember me</label>
      </div>
      <div>
        <button [disabled]="submitted && !form.valid" class="btn btn-primary">
          Login
        </button>
      </div>
    </form>
  </ng-container>

  <ng-template #tanFormTemplate>
    <alert type="warning" [hidden]="tanErrors.length === 0">
      <div *ngFor="let tanError of tanErrors">
        {{tanError}}
      </div>
    </alert>
    <form [formGroup]="tanForm" (ngSubmit)="onTanSubmit()">
      <div class="mb-3">
        <label class="form-label">Authenticator app</label>
        <input type="text" formControlName="tan" name="tan" class="form-control" [ngClass]="{ 'is-invalid': tanFormSubmitted && tanForm.get('tan')?.errors }" />
        <div *ngIf="tanFormSubmitted && tanForm.get('tan')?.errors">
          <div *ngIf="tanForm.get('tan')?.errors?.['required']">Tan is required</div>
        </div>
      </div>
      <div>
        <button [disabled]="tanFormSubmitted && !tanForm.valid" class="btn btn-primary">
          Login
        </button>
      </div>
    </form>
  </ng-template>
</div>
