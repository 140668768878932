import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * LegacyMessageService is designed to interact with a legacy app through messaging.
 *
 * It offers imperative, 'push' style functionality for delivering messages from legacy applications to Angular components.
 * The `updateMessage` method handles messages from the legacy application.
 * Other components/services can subscribe to these messages via the `currentMessage$` observable.
 */
@Injectable({
  providedIn: 'root'
})
export class LegacyMessageService {

  private messageSource = new BehaviorSubject<any>({});

  currentMessage$ = this.messageSource.asObservable();

  constructor() { }

  /**
   * Method to update the service's current message.
   *
   * It checks the kind of the message and updates the internal message state.
   *
   * @param {any} message - The incoming message.
   */
  updateMessage(message: any) {
    this.messageSource.next(message);
  }
}
