import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from "@angular/router";
import { LegacyMessageService } from "../services/legacy-message.service";

import { environment } from '../../environments/environment';
import { Subscription } from "rxjs";

/**
 * LegacyAppComponent serves as the host for the external legacy application.
 *
 * The legacy application is included within the Angular app by constructing a safe URL from the legacy application URL.
 * The safe URL is generated by the DomSanitizer to avert any possible security threats.
 *
 * The Component also manages redirection within the legacy application by listening to the window message event.
 * Messages from the legacy application received through these events are then handled further.
 */
@Component({
  selector: 'app-legacy-app',
  templateUrl: './legacy-app.component.html',
  styleUrls: ['./legacy-app.component.css']
})

export class LegacyAppComponent implements OnInit, OnDestroy {
  legacyAppUrl: string;
  safeLegacyAppUrl: SafeResourceUrl;
  private receiveMessageFn: any;
  private messageSubscription: Subscription = new Subscription();

  constructor(
    private sanitizer: DomSanitizer,
    private location: Location,
    private legacyMessageService: LegacyMessageService,
    private router: Router)
  {
    this.legacyAppUrl = environment.legacyAppUrl;
    this.safeLegacyAppUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.legacyAppUrl + this.router.url);
    this.receiveMessageFn = this.receiveMessage.bind(this);
  }

  /**
   * OnInit lifecycle hook. This method is executed after the component has been initialized.
   *
   * This method performs two primary operations:
   *
   * 1. Attaches an event listener to the 'message' event on the global window object.
   *    The listener uses the bound method `this.receiveMessageFn` as the callback function.
   *    This listener is used to handle postMessage events from the loaded legacy application in an iframe.
   *
   * 2. Subscribes to the `currentMessage$` Observable from the `LegacyMessageService`. This subscription provides
   *    a reactive stream of messages of the 'url_data' kind from the legacy application.
   *    - When such a message is received, the Angular app's URL is updated to match the state of the legacy application.
   *    - The message's text, which contains the current URL of the legacy application, is transformed into a path
   *      relative to the Angular application by cutting off the `legacyAppUrl` part.
   *
   * Storing the subscription in `messageSubscription` allows us to later unsubscribe from it in the `ngOnDestroy` method.
   */
  ngOnInit(): void {
    window.addEventListener('message', this.receiveMessageFn, false);

    this.messageSubscription = this.legacyMessageService.currentMessage$.subscribe(data => {
      if (data.kind === 'url_data') {
        const address = data.text.toString().replace(this.legacyAppUrl, '');
        this.location.replaceState(address);
      }
    });
  }

  /**
   * Handles incoming window messages. If the message originates from the legacy application,
   * it invokes the LegacyMessageService's updateMessage method with the incoming data.
   *
   * @param {MessageEvent} event - The incoming window message event.
   */
  receiveMessage(event: MessageEvent) {
    if (event.origin !== this.legacyAppUrl) {
      return;
    }

    this.legacyMessageService.updateMessage(event.data);
  }

  /**
   * Performed before the component's destruction, this method removes the 'message' event listener from the window object.
   *
   * @returns {void}
   */
  ngOnDestroy(): void {
    window.removeEventListener('message', this.receiveMessageFn, false);
    this.messageSubscription.unsubscribe();
  }
}
