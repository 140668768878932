import { ArrayDataSource, DataSource } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { ApolloError } from '@apollo/client';
import { Apollo } from 'apollo-angular';
import { GraphQLError } from 'graphql';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrentUserGQL, CurrentUserQuery, UsersGQL } from 'src/app/graphql/gql';
import { ColumnDefinition } from 'src/app/paginated-table/paginated-table.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent {

  loading = true;
  data!: CurrentUserQuery;
  errors: readonly GraphQLError[] | undefined;

  columns: ColumnDefinition[] = [
    {
      attr: 'email',
      label: 'Email',
      orderKey: 'email'
    },
    {
      attr: 'firstname',
      label: 'First Name'
    },
    {
      attr: 'lastname',
      label: 'Last Name'
    },
    {
      attr: 'state',
      label: 'State'
    },
    {
      attr: 'company',
      label: 'company'
    },
    {
      attr: 'street',
      label: 'street'
    },
    {
      attr: 'zip',
      label: 'zip'
    },
    {
      attr: 'city',
      label: 'city'
    },
    {
      attr: 'country',
      label: 'country'
    },
    {
      attr: 'country_state',
      label: 'country_state'
    },
    {
      attr: 'other_state',
      label: 'other_state'
    },
    {
      attr: 'phone',
      label: 'phone'
    },
    {
      attr: 'fax',
      label: 'fax'
    },
    {
      attr: 'cell_phone',
      label: 'cell_phone'
    },
    {
      attr: 'last_sign_in_at',
      label: 'last_sign_in_at'
    },
    {
      attr: 'allowed_ip_addresses',
      label: 'allowed_ip_addresses'
    },
    {
      attr: 'commercial_register_number',
      label: 'commercial_register_number'
    },
    {
      attr: 'tax_number_company',
      label: 'tax_number_company'
    },
    {
      attr: 'client_number',
      label: 'client_number'
    }
  ]
 
  constructor(private apollo: Apollo, public usersGQL: UsersGQL) {}

  dataMapper(response: any): any {
    return response.data.users;
  }

  onUserClick(user: any) {
    console.log(user)
  }
}
