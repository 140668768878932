import { ArrayDataSource, DataSource } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { ApolloError } from '@apollo/client';
import { Apollo } from 'apollo-angular';
import { GraphQLError } from 'graphql';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrentUserGQL, CurrentUserQuery, DomainsGQL, DomainsQuery } from 'src/app/graphql/gql';
import { ColumnDefinition, PaginatedTableCellType } from 'src/app/paginated-table/paginated-table.component';

@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.css']
})
export class DomainsComponent {

  loading = true;
  data!: CurrentUserQuery;
  errors: readonly GraphQLError[] | undefined;

  displayedColumns: string[] = ['name', 'tld', 'state', 'brand'];

  columns: ColumnDefinition[] = [
    {
      attr: 'name',
      label: 'Name',
      orderKey: 'name'
    },
    {
      attr: 'tld.name',
      label: 'TLD'
    },
    {
      attr: 'brand.name',
      label: 'Brand',
      orderKey: 'brand'
    },
    {
      attr: 'state',
      label: 'State'
    },
    {
      attr: 'idn',
      label: 'IDN'
    },
    {
      attr: 'basename',
      label: 'Basename'
    },
    {
      attr: 'expiresAt',
      label: 'Expires at',
      cellType: 'date'
    },
    {
      attr: 'transferredAt',
      label: 'Transferred at',
      cellType: 'date'
    },
    {
      attr: 'updatedAt',
      label: 'Updated at',
      cellType: 'date'
    },
    {
      attr: 'transferLock',
      label: 'Transfer lock',
      cellType: 'boolean'
    },
    {
      attr: 'updateLock',
      label: 'Update lock',
      cellType: 'boolean'
    },
    {
      attr: 'deleteLock',
      label: 'Delete lock',
      cellType: 'boolean'
    },
    {
      attr: 'clientManaged',
      label: 'Client managed',
      cellType: 'boolean'
    },
    {
      attr: 'renewalMode',
      label: 'Renewal mode'
    },
    {
      attr: 'premium',
      label: 'Premium',
      cellType: 'boolean'
    },
    {
      attr: 'supportsAuthcode',
      label: 'Supports authcode',
      cellType: 'boolean'
    },
    {
      attr: 'whoisPrivacy',
      label: 'Whois privacy',
      cellType: 'boolean'
    },
    {
      attr: 'costUnit',
      label: 'Cost unit'
    },
    {
      attr: 'adminContact.handle',
      label: 'Admin Contact: Handle',
      visible: false
    },
    {
      attr: 'adminContact.title',
      label: 'Admin Contact: Title',
      visible: false
    },
    {
      attr: 'adminContact.firstname',
      label: 'Admin Contact: Firstname',
      visible: false
    },
    {
      attr: 'adminContact.lastname',
      label: 'Admin Contact: Lastname',
      visible: false
    },
    {
      attr: 'adminContact.fax',
      label: 'Admin Contact: Fax',
      visible: false
    },
    {
      attr: 'adminContact.street',
      label: 'Admin Contact: Street',
      visible: false
    },
    {
      attr: 'adminContact.street1',
      label: 'Admin Contact: Street (additional line 1)',
      visible: false
    },
    {
      attr: 'adminContact.street2',
      label: 'Admin Contact: Street (additional line 2)',
      visible: false
    },
    {
      attr: 'adminContact.company',
      label: 'Admin Contact: Company',
      visible: false
    },
    {
      attr: 'adminContact.city',
      label: 'Admin Contact: City',
      visible: false
    },
    {
      attr: 'adminContact.zip',
      label: 'Admin Contact: Zip',
      visible: false
    },
    {
      attr: 'adminContact.phone',
      label: 'Admin Contact: Phone',
      visible: false
    },
    {
      attr: 'adminContact.countryState',
      label: 'Admin Contact: Country state',
      visible: false
    },
    {
      attr: 'adminContact.email',
      label: 'Admin Contact: Email',
      visible: false
    },
    {
      attr: 'ownerContact.handle',
      label: 'Owner Contact: Handle',
      visible: false
    },
    {
      attr: 'ownerContact.title',
      label: 'Owner Contact: Title',
      visible: false
    },
    {
      attr: 'ownerContact.firstname',
      label: 'Owner Contact: Firstname',
      visible: false
    },
    {
      attr: 'ownerContact.lastname',
      label: 'Owner Contact: Lastname',
      visible: false
    },
    {
      attr: 'ownerContact.fax',
      label: 'Owner Contact: Fax',
      visible: false
    },
    {
      attr: 'ownerContact.street',
      label: 'Owner Contact: Street',
      visible: false
    },
    {
      attr: 'ownerContact.street1',
      label: 'Owner Contact: Street (additional line 1)',
      visible: false
    },
    {
      attr: 'ownerContact.street2',
      label: 'Owner Contact: Street (additional line 2)',
      visible: false
    },
    {
      attr: 'ownerContact.company',
      label: 'Owner Contact: Company',
      visible: false
    },
    {
      attr: 'ownerContact.city',
      label: 'Owner Contact: City',
      visible: false
    },
    {
      attr: 'ownerContact.zip',
      label: 'Owner Contact: Zip',
      visible: false
    },
    {
      attr: 'ownerContact.phone',
      label: 'Owner Contact: Phone',
      visible: false
    },
    {
      attr: 'ownerContact.countryState',
      label: 'Owner Contact: Country state',
      visible: false
    },
    {
      attr: 'ownerContact.email',
      label: 'Owner Contact: Email',
      visible: false
    },
    {
      attr: 'techContact.handle',
      label: 'Technical Contact: Handle',
      visible: false
    },
    {
      attr: 'techContact.title',
      label: 'Technical Contact: Title',
      visible: false
    },
    {
      attr: 'techContact.firstname',
      label: 'Technical Contact: Firstname',
      visible: false
    },
    {
      attr: 'techContact.lastname',
      label: 'Technical Contact: Lastname',
      visible: false
    },
    {
      attr: 'techContact.fax',
      label: 'Technical Contact: Fax',
      visible: false
    },
    {
      attr: 'techContact.street',
      label: 'Technical Contact: Street',
      visible: false
    },
    {
      attr: 'techContact.street1',
      label: 'Technical Contact: Street (additional line 1)',
      visible: false
    },
    {
      attr: 'techContact.street2',
      label: 'Technical Contact: Street (additional line 2)',
      visible: false
    },
    {
      attr: 'techContact.company',
      label: 'Technical Contact: Company',
      visible: false
    },
    {
      attr: 'techContact.city',
      label: 'Technical Contact: City',
      visible: false
    },
    {
      attr: 'techContact.zip',
      label: 'Technical Contact: Zip',
      visible: false
    },
    {
      attr: 'techContact.phone',
      label: 'Technical Contact: Phone',
      visible: false
    },
    {
      attr: 'techContact.countryState',
      label: 'Technical Contact: Country state',
      visible: false
    },
    {
      attr: 'techContact.email',
      label: 'Technical Contact: Email',
      visible: false
    },
    {
      attr: 'billingContact.handle',
      label: 'Billing Contact: Handle',
      visible: false
    },
    {
      attr: 'billingContact.title',
      label: 'Billing Contact: Title',
      visible: false
    },
    {
      attr: 'billingContact.firstname',
      label: 'Billing Contact: Firstname',
      visible: false
    },
    {
      attr: 'billingContact.lastname',
      label: 'Billing Contact: Lastname',
      visible: false
    },
    {
      attr: 'billingContact.fax',
      label: 'Billing Contact: Fax',
      visible: false
    },
    {
      attr: 'billingContact.street',
      label: 'Billing Contact: Street',
      visible: false
    },
    {
      attr: 'billingContact.street1',
      label: 'Billing Contact: Street (additional line 1)',
      visible: false
    },
    {
      attr: 'billingContact.street2',
      label: 'Billing Contact: Street (additional line 2)',
      visible: false
    },
    {
      attr: 'billingContact.company',
      label: 'Billing Contact: Company',
      visible: false
    },
    {
      attr: 'billingContact.city',
      label: 'Billing Contact: City',
      visible: false
    },
    {
      attr: 'billingContact.zip',
      label: 'Billing Contact: Zip',
      visible: false
    },
    {
      attr: 'billingContact.phone',
      label: 'Billing Contact: Phone',
      visible: false
    },
    {
      attr: 'billingContact.countryState',
      label: 'Billing Contact: Country state',
      visible: false
    },
    {
      attr: 'billingContact.email',
      label: 'Billing Contact: Email',
      visible: false
    },
  ]
 
  constructor(private apollo: Apollo, public domainsGQL: DomainsGQL) {}

  dataMapper(response: any): any {
    return response.data.domains;
  }

  onDomainClick(domain: any) {
    console.log(domain)
  }
}
