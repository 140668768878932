import { CdkDialogContainer, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { PortalModule } from '@angular/cdk/portal';
import { Component, inject } from '@angular/core';

@Component({
  selector: 'app-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
  standalone: true,
  imports: [PortalModule]
})
export class DialogContainerComponent<C extends DialogConfig = DialogConfig> extends CdkDialogContainer<C> {

  private readonly dialogRef = inject(DialogRef);

  title = this.dialogRef.config.data?.title

  onCloseClick() {
    this.dialogRef.close()
  }

}
