<nav class="navbar fixed-bottom navbar-expand-sm">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" href="https://www.brandshelter.com/legal-notice/">Imprint</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.brandshelter.com/disclaimer/" target="_blank" rel="noopener noreferrer">Disclaimer</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.brandshelter.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.brandshelter.com/terms-conditions/" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.brandshelter.com/registration-agreement/" target="_blank" rel="noopener noreferrer">Registration Agreement</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.icann.org/resources/pages/responsibilities-2014-03-14-en" target="_blank" rel="noopener noreferrer">Registrant Rights and Responsibilities</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="http://localhost:3000/papers/Data%20Processing%20Agreement%20Key-Systems_May%202018.pdf" target="_blank" rel="noopener noreferrer">Data Processing Agreement</a>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <a class="nav-link" href="https://www.key-systems.net/en/homepage" target="_blank" rel="noopener noreferrer">&copy; Key-Systems GmbH</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
