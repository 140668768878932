import { Query } from 'apollo-angular';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { PageInfo } from '../graphql/gql';

interface GQLConnection  {
  edges: any[];
  nodes: any[];
  pageInfo: PageInfo;
  totalCount: number | null;
}

interface PaginatedConnectionParams {
  first?: number;
  last?: number;
  after?: string;
  before?: string;
  orderBy?: {
    key: string;
    asc?: boolean
  }
  count: boolean;
}

export class TableDataSource {
  private dataSubject = new BehaviorSubject<any[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private query: Query<any, any>;
  private dataMapper: (response: any) => any;

  public loading$ = this.loadingSubject.asObservable();
  public totalCount$ = this.totalCountSubject.asObservable();
  public dataSubject$ = this.dataSubject.asObservable();

  constructor(
    query: Query<any, any>,
    dataMapper: (response: any) => any
  ) {
    this.query = query;
    this.dataMapper = dataMapper;
  }

  connect(): Observable<any[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  loadData(pageIndex: number, pageSize: number, orderKey: string | null = null, orderAsc: boolean = true, count: boolean = false) {
    this.loadingSubject.next(true);

    const queryParams: PaginatedConnectionParams = {
      first: pageIndex * pageSize,
      last: pageSize,
      count
    };

    if(orderKey) {
      queryParams.orderBy = {
        key: orderKey,
        asc: orderAsc
      }
    }

    this.query.fetch(queryParams)
      .pipe(map(r => this.dataMapper(r)))
      .subscribe(data => {
        const gqlConnection = (data as GQLConnection)

        if(gqlConnection.hasOwnProperty('totalCount')) {
          this.totalCountSubject.next(gqlConnection.totalCount!)
        }

        this.dataSubject.next(gqlConnection.edges.map(edge => edge.node));
        this.loadingSubject.next(false);
      }
    );
  }
}
