import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { ApolloClientOptions, ApolloLink, InMemoryCache, concat } from '@apollo/client/core';
import { environment } from 'src/environments/environment';
import { setContext } from '@apollo/client/link/context';
import { AuthTokenService } from './services/auth-token.service';

export function createApollo(httpLink: HttpLink, authTokenService: AuthTokenService): ApolloClientOptions<any> {
  const authenticityTokenRequestHandler = setContext(async (operation, prevContext) => {
    const authTokens = await authTokenService.getAuthTokens()

    const headers: Record<string, any> = {
      "X-CSRF-Token": authTokens.authenticityToken
    }

    const frontendApiBasicAuth = authTokens.frontendApiBasicAuth
    if (frontendApiBasicAuth) {
      headers["Authorization"] = "Basic " + frontendApiBasicAuth
    }

    return { headers }
  })

  const uri = environment.legacyAppUrl + '/graphql_frontend';
  const sourceLink = httpLink.create({ uri, withCredentials: true })

  return {
    link: concat(authenticityTokenRequestHandler, sourceLink),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      mutate: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      }
    },
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthTokenService],
    },
  ],
})
export class GraphQLModule {}
