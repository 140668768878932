<div class="login-card card">
  <div class="header mb-3">
    <img alt="Logo" src="/assets/images/logo.png">
    <a class="text-white" [routerLink]="['/login']">Back to login</a>
  </div>

  <ng-container>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class='col-6'>
          <div class="mb-2">
            <label class="form-label">Title</label>
            <select class="form-control" formControlName="title" name="user[title]">
              <option value="mr">Mr</option>
              <option value="ms">Ms</option>
            </select>
          </div>
          <div class="mb-2">
            <label class="form-label">First name</label>
            <input type="text" formControlName="firstname" name="user[firstname]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('firstname')?.errors }" />
            <div *ngIf="submitted && form.get('firstname')?.errors">
              <div *ngIf="form.get('firstname')?.errors?.['required']">First name is required</div>
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">Last name</label>
            <input type="text" formControlName="lastname" name="user[lastname]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('lastname')?.errors }" />
            <div *ngIf="submitted && form.get('lastname')?.errors">
              <div *ngIf="form.get('lastname')?.errors?.['required']">Last name is required</div>
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">Organization</label>
            <input type="text" formControlName="company" name="user[company]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('company')?.errors }" />
            <div *ngIf="submitted && form.get('company')?.errors">
              <div *ngIf="form.get('company')?.errors?.['required']">Organization is required</div>
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">Street</label>
            <input type="text" formControlName="street" name="user[street]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('street')?.errors }" />
            <div *ngIf="submitted && form.get('street')?.errors">
              <div *ngIf="form.get('street')?.errors?.['required']">Street is required</div>
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">Zip</label>
            <input type="text" formControlName="zip" name="user[zip]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('zip')?.errors }" />
            <div *ngIf="submitted && form.get('zip')?.errors">
              <div *ngIf="form.get('zip')?.errors?.['required']">Zip is required</div>
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">City</label>
            <input type="text" formControlName="city" name="user[city]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('city')?.errors }" />
            <div *ngIf="submitted && form.get('city')?.errors">
              <div *ngIf="form.get('city')?.errors?.['required']">City is required</div>
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">Country</label>
            <select class="form-control" formControlName="country" name="user[country]" (change)="fetchCountryStates($event)">
              <option *ngFor="let countryCode of countryCodes" [value]="countryCode">
                {{ countryCode }}
              </option>
            </select>
          </div>
          <div class="mb-2">
            <label class="form-label">State</label>
            <select class="form-control" formControlName="country_state" name="user[country_state]">
              <option *ngFor="let state of countryStates" [value]="state.id">
                {{ state.name }}
              </option>
            </select>
          </div>
          <div class="mb-2">
            <label class="form-label">Telephone Number</label>
            <input type="text" formControlName="phone" name="user[phone]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('phone')?.errors }" />
            <div *ngIf="submitted && form.get('phone')?.errors">
              <div *ngIf="form.get('phone')?.errors?.['required']">Telephone Number is required</div>
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">Cellphone</label>
            <input type="text" formControlName="cell_phone" name="user[cell_phone]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('cell_phone')?.errors }" />
            <div *ngIf="submitted && form.get('cell_phone')?.errors">
              <div *ngIf="form.get('cell_phone')?.errors?.['required']">Cellphone is required</div>
            </div>
          </div>

          <div class="form-check mb-2">
            <input class="form-check-input" formControlName="accept_dpa" type="checkbox" name="user[accept_dpa]" value="" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">I acknowledge and accept BrandShelter's Data Processing Agreement</label>
          </div>
          <div class="form-check mb-2">
            <input class="form-check-input" formControlName="accept_privacy_policy" type="checkbox" name="user[accept_privacy_policy]" value="" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">I acknowledge and accept Privacy Policy</label>
          </div>
        </div>






        <div class='col-6'>
          <div class="mb-2">
            <label class="form-label">Email</label>
            <input type="text" formControlName="email" name="user[email]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('email')?.errors }" />
            <div *ngIf="submitted && form.get('email')?.errors">
              <div *ngIf="form.get('email')?.errors?.['required']">Username is required</div>
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">Password</label>
            <input type="password" formControlName="password" name="user[password]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('password')?.errors }" />
            <div *ngIf="submitted && form.get('password')?.errors">
              <div *ngIf="form.get('commercial_register_number')?.errors?.['required']">Password is required</div>
              <div *ngIf="form.get('password')?.errors?.['minlength']">Password requires at least 12 characters</div>
              <div *ngIf="form.get('password')?.errors?.['pattern']">Password requires at least 1 lowercase letter, 1 uppercase letter, 1 digit and 1 special character</div>
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">Password Confirmation</label>
            <input type="password" formControlName="password_confirmation" name="user[password_confirmation]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('password_confirmation')?.errors }" />
            <div *ngIf="submitted && form.get('password_confirmation')?.errors">
              <div *ngIf="form.get('password_confirmation')?.errors?.['required']">Password confirmation is required</div>
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">commercial_register_number</label>
            <input type="text" formControlName="commercial_register_number" name="user[commercial_register_number]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('commercial_register_number')?.errors }" />
            <div *ngIf="submitted && form.get('commercial_register_number')?.errors">
              <div *ngIf="form.get('commercial_register_number')?.errors?.['required']">commercial_register_number is required</div>
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">Identity card number</label>
            <input type="text" formControlName="id_card_number" name="user[id_card_number]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('id_card_number')?.errors }" />
            <div *ngIf="submitted && form.get('id_card_number')?.errors">
              <div *ngIf="form.get('id_card_number')?.errors?.['required']">id_card_number is required</div>
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">Tax number (company)</label>
            <input type="text" formControlName="tax_number_company" name="user[tax_number_company]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('tax_number_company')?.errors }" />
            <div *ngIf="submitted && form.get('tax_number_company')?.errors">
              <div *ngIf="form.get('tax_number_company')?.errors?.['required']">tax_number_company is required</div>
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">Tax number (personal)</label>
            <input type="text" formControlName="tax_number_personal" name="user[tax_number_personal]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('tax_number_personal')?.errors }" />
            <div *ngIf="submitted && form.get('tax_number_personal')?.errors">
              <div *ngIf="form.get('tax_number_personal')?.errors?.['required']">tax_number_personal is required</div>
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">Security Question</label>
            <input type="text" formControlName="passphrase" name="user[passphrase]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('passphrase')?.errors }" />
            <div *ngIf="submitted && form.get('passphrase')?.errors">
              <div *ngIf="form.get('passphrase')?.errors?.['required']">passphrase is required</div>
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">Matching answer</label>
            <input type="text" formControlName="answer" name="user[answer]" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('answer')?.errors }" />
            <div *ngIf="submitted && form.get('answer')?.errors">
              <div *ngIf="form.get('answer')?.errors?.['required']">Matching answer is required</div>
            </div>
          </div>

        </div>
      </div>
      
      
      <div>
        <button [disabled]="submitted && !form.valid" class="btn btn-primary">
          Login
        </button>
      </div>
    </form>
  </ng-container>
</div>
