import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';
import { CurrentUserService } from "../services/current-user.service";
import { CurrentAccountService } from "../services/current-account.service";
import { zip } from "rxjs";
import { AuthTokenService } from '../services/auth-token.service';

/**
 * LoginComponent is a component that handles the login functionality for the application.
 *
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form!: FormGroup;
  tanForm!: FormGroup;
  submitted = false;
  tanFormSubmitted = false;
  invalidUserOrPassword = false
  tanFormVisible = false
  tanErrors = []

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private currentAccountService: CurrentAccountService,
    private currentUserService: CurrentUserService,
    private authTokenService: AuthTokenService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember_me: ['']
    });

    this.tanForm = this.formBuilder.group({
      tan: ['', Validators.required],
    });
  }

  /**
   * Submits the form data and handles the response from the API.
   *
   * @async
   * @function onSubmit
   * @returns {Promise} A Promise that resolves when the API responds with data.
   */
  async onSubmit(): Promise<any> {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    let body = encodeURIComponent("user[login]") + "=" + encodeURIComponent(this.form.value.username) + "&" +
               encodeURIComponent("user[password]") + "=" + encodeURIComponent(this.form.value.password) + "&" +
               encodeURIComponent("user[remember_me]") + "=" + encodeURIComponent(this.form.value.remember_me ? "1" : "0")

    let response = await fetch(environment.legacyAppUrl + "/users/sign_in", {
      "headers": {
        "accept": "application/json",
        "content-type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": await this.authTokenService.getAuthenticityToken(true)
      },
      "body": body,
      "method": "POST",
      "credentials": 'include'
    });
    await this.handleResponse(response)
  }

  /**
   * Submits the TAN form to the server.
   *
   * @async
   * @returns {void}
   */
  async onTanSubmit(): Promise<void> {
    this.tanFormSubmitted = true;

    // stop here if form is invalid
    if (this.tanForm.invalid) {
      return;
    }

    let body = encodeURIComponent("tan") + "=" + encodeURIComponent(this.tanForm.value.tan) + "&" +
               encodeURIComponent("user[remember_me]") + "=" + encodeURIComponent(this.form.value.remember_me ? "1" : "0")

    let response = await fetch(environment.legacyAppUrl + "/users/sign_in", {
      "headers": {
        "accept": "application/json",
        "content-type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": await this.authTokenService.getAuthenticityToken(true)
      },
      "body": body,
      "method": "POST",
      "credentials": 'include'
    });
    await this.handleResponse(response)
  }

  /**
   * Handles the response from an API request.
   *
   * @param {Response} response - The response object received from the API request.
   * @return {Promise} - A promise that resolves once the response has been handled.
   */
  private async handleResponse(response: Response): Promise<any> {
    switch(response.status) {
      case 201:
        await this.handleUserCreated();
        break;
      case 401:
        this.handleUnauthorized();
        break;
      case 200:
        await this.handleOk(response);
        break;
    }
  }

  /**
   * Handles the event when a user is created.
   * This method updates the current account and user, and navigates to the home page.
   * @note This function doesn't handle any errors that might occur while fetching the tokens.
   * If needed, error handling should be added.
   * @note The promise returned by `this.router.navigateByUrl("/")` is not handled.
   * If required, to perform actions after navigation is complete, handle this promise.
   * @return {void}
   */
  private async handleUserCreated(): Promise<void> {
    try {
      const authTokens = await this.authTokenService.getAuthTokens(true);

      zip(
        this.currentUserService.getCurrentUser(),
        this.currentAccountService.getCurrentAccount()
      ).subscribe(([user, account]) => {
        this.currentAccountService.updateCurrentAccount(account);
        this.currentUserService.updateCurrentUser(user);
        this.router.navigateByUrl("/");
      });
    } catch (error) {
      // Optional: Handle any error that may have occurred while fetching the tokens.
    }
  }

  /**
   * Handles unauthorized access by setting the "invalidUserOrPassword" flag to true and hiding the TAN form.
   *
   * @private
   * @return {void} The method does not return a value.
   */
  private handleUnauthorized(): void {
    this.invalidUserOrPassword = true;
    this.tanFormVisible = false;
  }

  /**
   * Handles the response when it is successful.
   *
   * @param {Response} response - The response from the server.
   *
   * @return {Promise<void>} The promise that resolves when the handling is completed.
   */
  private async handleOk(response: Response): Promise<void> {
    let response_json = await response.json();
    if(response_json.status == "tan_required") {
      this.tanFormVisible = true;
      this.tanErrors = response_json.errors;
    } else {
      this.tanFormVisible = false;
      this.form.reset();
      this.submitted = false;
    }
  }
}
