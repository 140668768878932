import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { LegacyMessageService } from "../services/legacy-message.service";

export type ToastSeverity = "info" | "success" | "warning" | "error"

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastr: ToastrService, private legacyMessageService: LegacyMessageService) {
    this.initLegacyFlashMessageHandling();
  }

  show(severity: ToastSeverity, message: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    switch(severity) {
      case 'info': return this.toastr.info(message, title, override)
      case 'success': return this.toastr.success(message, title, override)
      case 'warning': return this.toastr.warning(message, title, override)
      case 'error': return this.toastr.error(message, title, { disableTimeOut: true, ...override })
    }
  }

  /**
   * The method subscribes to the observable 'currentMessage$' exposed by the LegacyMessageService.
   * The subscription callback checks if the incoming message is of the kind 'flash_message'.
   * If true, it calls the 'show' method to deliver the flash message to the user. The 'show' method is expected to handle the visual representation of the flash message.
   * For a 'flash_message', it maps and utilizes the severity of the message as one of the parameters for the 'show' method
   * along with the text of the flash message.
   */
  initLegacyFlashMessageHandling() {
    this.legacyMessageService.currentMessage$.subscribe(
      (data: any) => {
        if (data.kind === 'flash_message') {
          this.show(this.mapLegacyFlashMessageSeverity(data.severity), data.text);
        }
      }
    );
  }

  private mapLegacyFlashMessageSeverity(severity: string): ToastSeverity {
    switch(severity) {
      case "success": return "success"
      case "error": return "error"
      default: return "info"
    }
  }
}
