import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { PaginatedTableComponent } from './paginated-table.component';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [PaginatedTableComponent],
  imports: [
    CommonModule,
    TableModule,
    CheckboxModule,
    FormsModule,
  ],
  exports: [
    PaginatedTableComponent
  ]
})

export class PaginatedTableModule {}