<div class="dialog-container">
  <div class="dialog-header">
    <div class="dialog-title">{{ title }}</div>
    <div class="dialog-close" (click)="onCloseClick()">
      &times;
    </div>
  </div>
  <div class="dialog-body">
    <ng-template cdkPortalOutlet></ng-template>
  </div>
  <div class="dialog-footer">

  </div>
</div>