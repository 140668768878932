import { Injectable } from '@angular/core';
import { BrandingByHostGQL, BrandingByHostQuery } from "../graphql/gql";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

/**
 * Service for managing branding information.
 * @class
 */
@Injectable({
  providedIn: 'root'
})
export class BrandingService {
  // @ts-ignore
  private BrandingSubject = new BehaviorSubject<BrandingByHostQuery>(null);
  public BrandingByHost = this.BrandingSubject.asObservable();
  constructor(private brandingByHostGQL: BrandingByHostGQL) {
   this.loadInitialData();
  }

  /**
   * Fetches initial data from brandingByHostGQL GraphQL endpoint
   * and emits the resulting branding data to the subscribed observers.
   *
   * @return {void}
   */
  loadInitialData(): void {
    this.brandingByHostGQL.fetch({ host: 'secure.brandshelter.com' }, { errorPolicy: 'all' }).pipe(
      map(result => result.data)
    ).subscribe(branding => this.BrandingSubject.next
    (branding));
  }

  /**
   * Retrieves branding information based on the provided host.
   *
   * @param {string} host - The host for which branding information is to be retrieved.
   * @return {Observable<BrandingByHostQuery>} - An Observable that emits the branding information.
   */
  getBrandingByHost(host: string): Observable<BrandingByHostQuery> {
    return this.brandingByHostGQL.fetch( { host: host }, { errorPolicy: 'all' }).pipe(
      map(result => result.data)
    );
  }
}
