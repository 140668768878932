<nav *ngIf="currentUser" class="navbar navbar-expand-lg p-0">
  <div class="container-fluid">
    <a class="navbar-brand p-0" href="#">
      <img ngSrc="/assets/images/brandshelter-green.png" alt="Logo" class="logo" height="32" width="174" priority="1"/>
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav ms-auto" >
        <li class="nav-item">
          <a class="nav-link" aria-current="page" [routerLink]="['/']">Dashboard</a>
        </li>
        <li class="nav-item dropdown" dropdown>
          <a class="nav-link dropdown-toggle" id="navbarDomainsDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" dropdownToggle>
            Domains
          </a>
          <ul class="dropdown-menu multi-column-dropdown" aria-labelledby="navbarDomainsDropdownMenuLink" *dropdownMenu>
            <div class="row">
              <div class="col">
                <h6 class="dropdown-header">Management</h6>
                <li><a class="dropdown-item" [routerLink]="['/domains']">Portfolio</a></li>
                <li><a class="dropdown-item" [routerLink]="['/domains/check/new']">Register</a></li>
                <li><a class="dropdown-item" [routerLink]="['/domains/transfers/new']">Transfers</a></li>
                <li><a class="dropdown-item" [routerLink]="['/domains/externals/new']">Externals</a></li>
              </div>
              <div class="col">
                <h6 class="dropdown-header">Services</h6>
                <li><a class="dropdown-item" [routerLink]="['/domains/check/new']">Availability</a></li>
                <li><a class="dropdown-item" [routerLink]="['/contacts']">Contacts</a></li>
                <li><a class="dropdown-item" [routerLink]="['/contact_sets']">Contact Sets</a></li>
                <li><a class="dropdown-item" [routerLink]="['/nameserver_sets']">Nameserver Sets</a></li>
                <li><a class="dropdown-item" [routerLink]="['/dns/resource_records']">Bulk DNS</a></li>
                <li><a class="dropdown-item" [routerLink]="['/domain_groups']">Groups</a></li>
                <li><a class="dropdown-item" [routerLink]="['/tld_groups']">TLDs</a></li>
              </div>
              <div class="col">
                <h6 class="dropdown-header">Tools</h6>
                <li><a class="dropdown-item" [routerLink]="['/services/whois/new']">Whois</a></li>
                <li><a class="dropdown-item" [routerLink]="['/services/dig/new']">Dig</a></li>
                <li><a class="dropdown-item" [routerLink]="['/services/nameserver_check/new']">Nameserver Check</a></li>
                <li><a class="dropdown-item" href="https://www.iana.org/domains/root/db" target="_blank" rel="noopener noreferrer">IANA Route Zone Database</a></li>
                <li><a class="dropdown-item" [routerLink]="['/services/idn_punycode_translation']">IDN/Punycode Conversion</a></li>
                <li><a class="dropdown-item" [routerLink]="['/nameservers']">Nameserver Glue Records</a></li>
                <li><a class="dropdown-item" [routerLink]="['/services/zone_import/new']">DNS Zone Import</a></li>
                <li><a class="dropdown-item" [routerLink]="['/services/ds_records/new']">DS Records</a></li>
              </div>
            </div>
          </ul>
        </li>
        <li class="nav-item dropdown" dropdown>
          <a class="nav-link dropdown-toggle" id="navbarBrandsDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" dropdownToggle>
            Brands
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarBrandsDropdownMenuLink" *dropdownMenu>
            <li><a class="dropdown-item" [routerLink]="['/brands']">Brands</a></li>
            <li><a class="dropdown-item" [routerLink]="['/trademarks']">Trademarks</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/ssl_certificates']">Certificates</a>
        </li>
        <li class="nav-item dropdown position-relative" dropdown>
          <a class="nav-link dropdown-toggle" id="navbarBrandProtectionDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" dropdownToggle>
            Brand Protection
          </a>
          <ul class="dropdown-menu multi-column-dropdown dropdown-menu-end" aria-labelledby="navbarBrandProtectionDropdownMenuLink" *dropdownMenu>
            <div class="row">
              <div class="col">
                <h6 class="dropdown-header">Domain Names</h6>
                <li><a class="dropdown-item" href="https://portal.bp.brandshelter.com" target="_blank" rel="noopener noreferrer">My Monitorings</a></li>
                <li><a class="dropdown-item" [routerLink]="['/brand_monitorings/WorkRequest::BrandMonitoring::DomainAudit/new']">Domain Audit</a></li>
                <li><a class="dropdown-item" [routerLink]="['/brand_monitorings/WorkRequest::BrandMonitoring::DomainMonitoring/new']">Domain Monitoring</a></li>
                <li><a class="dropdown-item" [routerLink]="['/brand_monitorings/WorkRequest::BrandMonitoring::WebPageAndWhoisMonitoring/new']">Web Page and Whois Monitoring</a></li>
                <li><a class="dropdown-item" [routerLink]="['/brand_monitorings//WorkRequest::BrandMonitoring::RegistrantSearch/new']">Registrant Search/Monitoring</a></li>
                <h6 class="dropdown-header">Internet Monitoring</h6>
                <li><a class="dropdown-item" [routerLink]="['/brand_monitorings/WorkRequest::BrandMonitoring::ContentMonitoring/new']">Content Monitoring</a></li>
                <li><a class="dropdown-item" [routerLink]="['/brand_monitorings/WorkRequest::BrandMonitoring::SocialMediaMonitoring/new']">Social Media</a></li>
                <li><a class="dropdown-item" [routerLink]="['/brand_monitorings/WorkRequest::BrandMonitoring::LogoMonitoring/new']">Logo Monitoring</a></li>
              </div>
              <div class="col">
                <h6 class="dropdown-header">Enforcement</h6>
                <li><a class="dropdown-item" [routerLink]="['/brand_monitorings/WorkRequest::Takedown/new']">Takedown</a></li>
                <li><a class="dropdown-item" [routerLink]="['/brand_monitorings/WorkRequest::RecoverDomain/new']">Domain Recovery</a></li>
                <li><a class="dropdown-item" [routerLink]="['/brand_monitorings/WorkRequest::DisclosureWhois/new']">Whois Disclosure/Verification</a></li>
                <li><a class="dropdown-item" [routerLink]="['/brand_monitorings/WorkRequest::ResolveDispute/new']">Dispute Resolution</a></li>
                <li><a class="dropdown-item" [routerLink]="['/brand_monitorings/WorkRequest::BrandMonitoring::Consultation/new']">Consultation</a></li>
                <h6 class="dropdown-header">Second Market</h6>
                <li><a class="dropdown-item" [routerLink]="['/brand_monitorings/WorkRequest::PurchaseDomain/new']">Domain Acquisition</a></li>
                <li><a class="dropdown-item" [routerLink]="['/brand_monitorings/WorkRequest::DomainBrokerage/new']">Domain Brokerage</a></li>
                <li><a class="dropdown-item" [routerLink]="['/brand_monitorings/WorkRequest::Snapback/new']">Backorder</a></li>
                <li><a class="dropdown-item" [routerLink]="['/brand_monitorings/WorkRequest::BrandMonitoring::Consultation/new']">Consultation</a></li>
              </div>
            </div>
          </ul>
        </li>
        <li class="nav-item dropdown" dropdown>
          <a class="nav-link dropdown-toggle" id="navbarBillingDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" dropdownToggle>
            Billing
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarBillingDropdownMenuLink" *dropdownMenu>
            <li><a class="dropdown-item" [routerLink]="['/invoices']">Invoices</a></li>
            <li><a class="dropdown-item" [routerLink]="['/billing_configs']">Settings</a></li>
            <li><a class="dropdown-item" [routerLink]="['/bank_accounts']">Bank Details</a></li>
            <li><a class="dropdown-item" [routerLink]="['/credit_cards']">Credit Cards</a></li>
            <li><a class="dropdown-item" [routerLink]="['/prices']">View Prices</a></li>
            <li><a class="dropdown-item" [routerLink]="['/recurring_billings']">Recurring Billing</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/work_requests']">Open Work</a>
        </li>
        <li class="nav-item">
          <a class="nav-link">|</a>
        </li>
        <li  *ngIf="checkPermissions()" class="nav-item dropdown position-relative" dropdown>
          <a class="nav-link dropdown-toggle" id="navbarAdminMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" dropdownToggle>
            Admin
          </a>
          <ul class="dropdown-menu multi-column-dropdown dropdown-menu-end" aria-labelledby="navbarAdminMenuLink" *dropdownMenu>
            <div class="row">
              <div class="col">
                <li><a class="dropdown-item" [routerLink]="['/admin/accounts']">Accounts</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/work_requests']">Work Requests</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/domains']">Domains</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/dns_keys']">DNSSEC Keys</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/ssl_certificates']">SSL Certificates</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/logbook/entries']">Logs</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/users']">Users</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/contacts']">Contacts</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/aggregated_stats']">Statistics</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/events']">CNIC Reseller Events</a></li>
              </div>
              <div class="col">
                <li><a class="dropdown-item" [routerLink]="['/admin/news']">News</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/tan_sets']">TAN-Sets</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/balances']">Balances</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/billing/bulk_price_changes/new']">Bulk Price Change</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/invoices']">Invoices</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/invoice_items']">Invoice Items</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/billing/recurring_billings']">Recurring Billing</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/billing/debtors']">Debtors List</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/user_categories']">User Categories</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/sso_configs']">Single Sign-On</a></li>
                <li><a class="dropdown-item" [routerLink]="['/admin/user_sessions']">Sessions</a></li>
              </div>
            </div>
          </ul>
        </li>
        <li class="nav-item dropdown position-relative" dropdown>
          <a class="nav-link dropdown-toggle" id="navbarUserMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" dropdownToggle>
            {{ currentUserName() }}
          </a>
          <ul class="dropdown-menu multi-column-dropdown dropdown-menu-end" aria-labelledby="navbarUserMenuLink" *dropdownMenu>
            <div class="row">
              <div class="col">
                <li><a class="dropdown-item" [routerLink]="['/news']">News</a></li>
                <li><a class="dropdown-item" [routerLink]="['/notes']">Notes</a></li>
                <li><a class="dropdown-item" [routerLink]="['/logbook/entries']">Logs</a></li>
                <li><a class="dropdown-item" [routerLink]="['/subusers']">Users</a></li>
                <li><a class="dropdown-item" [routerLink]="['/divisions']">Divisions</a></li>
                <li><a class="dropdown-item" [routerLink]="['/approval/procedures']">Approval</a></li>
                <li><a class="dropdown-item" [routerLink]="['/personal_data/edit']">Personal Data</a></li>
              </div>
              <div class="col">
                <li><a class="dropdown-item" [routerLink]="['/news']">Change Language</a></li>
                <li><a class="dropdown-item" [routerLink]="['/documents']">Files</a></li>
                <li><a class="dropdown-item" [routerLink]="['/settings']">Settings</a></li>
                <li><a class="dropdown-item" [routerLink]="['/access_tokens']">Access Tokens</a></li>
                <li><a class="dropdown-item" [routerLink]="['/branding_settings']">Branding</a></li>
                <li><a class="dropdown-item" [routerLink]="['/custom_sessions']">Sessions</a></li>
                <li><a class="dropdown-item" (click)="logout()">Logout</a></li>
              </div>
            </div>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/cart']">Cart (1)</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
