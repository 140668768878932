import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment"

type AuthTokens = {
  authenticityToken: string
  frontendApiBasicAuth: string | null
}

/**
 * Service for fetching the authenticity token (CSRF) and the frontend API basic auth token.
 * @class
 */
@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {
  private authTokensPromise: Promise<AuthTokens> | null = null

  async getAuthenticityToken(refresh = false): Promise<string> {
    const authTokens = await this.getAuthTokens(refresh)
    return authTokens.authenticityToken
  }

  async getFrontendApiBasicAuth(refresh = false): Promise<string | null> {
    const authTokens = await this.getAuthTokens(refresh)
    return authTokens.frontendApiBasicAuth
  }

  async getAuthTokens(refresh = false): Promise<AuthTokens> {
    if(this.authTokensPromise == null || refresh) {
      this.authTokensPromise = this.fetchAuthTokens()
    }
    return this.authTokensPromise
  }

  clear() {
    this.authTokensPromise = null
  }

  async fetchAuthTokens(): Promise<AuthTokens> {
    let response = await fetch(environment.legacyAppUrl + "/authenticity_token", {
      "headers": {
      "accept": "application/json",
      },
      "method": "GET",
      "credentials": 'include'
    })
    const response_json = await response.json()

    const authenticityToken = response_json.authenticity_token
    const frontendApiBasicAuth = response_json.frontend_api_basic_auth

    return { authenticityToken, frontendApiBasicAuth }
  }
  
}
