import { Query } from 'apollo-angular';
import { Component, Input, OnInit, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { TableDataSource } from './table-data-source';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Dialog } from '@angular/cdk/dialog';
import { TableLazyLoadEvent } from 'primeng/table';

export type PaginatedTableCellType = 'date' | 'boolean'

export interface ColumnDefinition {
  attr: string;
  label: string;
  orderKey?: string;
  visible?: boolean;
  cellType?: PaginatedTableCellType;
  template?: () => TemplateRef<any>;
}

class Column {
  columnDef: ColumnDefinition
  visible: boolean

  constructor(columnDef: ColumnDefinition) {
    this.columnDef = columnDef
    this.visible = columnDef.visible == undefined ? true : columnDef.visible
  }
}

@Component({
  selector: 'app-paginated-table',
  templateUrl: './paginated-table.component.html',
  styleUrls: ['./paginated-table.component.scss'],
})
export class PaginatedTableComponent implements OnInit {
  @Output() rowClick = new EventEmitter();
  @Input("columns") columnDefs!: ColumnDefinition[];
  @Input() pageSize = 5;
  @Input() pageSizeOptions: number[] = [5, 25, 50, 100, 200, 500];
  @Input() query!: Query<any, any>;
  @Input() dataMapper(response: any): any { return response.data; }
  dataSource!: TableDataSource;
  value!: any[];
  totalItemCount = 0;
  isLoading = true;
  private pageIndex = 1;
  protected orderKey: string | null = null
  protected orderAsc: boolean = true
  protected columns: Column[] = []
  totalRecords!: number;
  selection: any[] = [];
  selectAll: boolean = false;

  @ViewChild('tableSettingsTemplate', { read: TemplateRef }) tableSettingsTemplate!: TemplateRef<any>;

  @ViewChild('dateCellTemplate', { read: TemplateRef }) dateCellTemplate!: TemplateRef<any>;
  @ViewChild('booleanCellTemplate', { read: TemplateRef }) booleanCellTemplate!: TemplateRef<any>;

  constructor(public dialog: Dialog) {}

  /**
   * Deep access in objects
   *
   * @example
   * // Returns { d: 4 }
   * let myHash = { a: { b: { d: 4 }, c: 3 } };
   * dig(myHash, 'a.b');
   *
   * // Returns 4
   * dig(myHash, 'a.b.d');
   */
  dig(obj: any, path: string): any {
    return obj && path.split('.').reduce(
      (result, attr) => result == null ? undefined : result[attr], obj
    );
  }

  ngOnInit() {
    this.columns = this.columnDefs.map(col => new Column(col))

    this.dataSource = new TableDataSource(this.query, this.dataMapper);
    this.dataSource.dataSubject$.subscribe(data => {
      this.value = data;
      if(this.selectAll) {
        this.selection = [...this.value];
      }
    })
    this.dataSource.totalCount$.subscribe(count => this.totalItemCount = count);
    this.dataSource.loading$.subscribe(isLoading => this.isLoading = isLoading);
    this.loadData(true)
  }

  isRowClickBound(): boolean {
    return this.rowClick.observers.length > 0;
  }

  onRowClick(row: any) {
    this.rowClick.emit(row);
  }

  pageChanged(event: PageChangedEvent) {
    this.pageIndex = event.page;
    this.pageSize = event.itemsPerPage;
    this.loadData()
  }

  loadData(count = false) {
    this.dataSource.loadData(this.pageIndex, this.pageSize, this.orderKey, this.orderAsc, count);
  }

  onSettingsIconClick(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()

    this.dialog.open(this.tableSettingsTemplate, { data: { title: "Table settings" }})
  }

  onChangeColumnDisplayed(column: Column) {
    column.visible = !column.visible
  }

  getTemplate(column: ColumnDefinition): TemplateRef<any> | null {
    if(column.cellType) {
      switch(column.cellType) {
        case 'date': { return this.dateCellTemplate }
        case 'boolean': { return this.booleanCellTemplate }
      }
    } else if (column.template) {
      column.template()
    }
    return null
  }

  onLazyLoad(event: TableLazyLoadEvent) {
    console.log("onLazyLoad", event)
    this.pageIndex = (event.first! / event.rows!) + 1
    this.pageSize = event.rows!
    this.orderKey = event.sortField as string | null
    this.orderAsc = event.sortOrder == 1
    this.loadData()
  }

  getVisibleColumns() {
    return this.columns.filter(c => c.visible)
  }

  onSelectionChange(value = []) {
    console.log("onSelectionChange", value)
    this.selectAll = value.length === this.totalRecords;
    this.selection = value;
  }

  onSelectAllChange(event: any) {
    console.log("onSelectAllChange", event, this.selection)
    const checked = event.checked;

    if (checked) {
      this.selectAll = true;
      this.selection = [...this.value];
    } else {
      this.selection = [];
      this.selectAll = false;
    }
  }
}
