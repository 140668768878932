<h1 i18n>Admin domains</h1>

<app-paginated-table [columns]="columns" [query]="domainsGQL" [dataMapper]="dataMapper" (rowClick)="onDomainClick($event)">
</app-paginated-table>

<!-- <p-toolbar>
  <div class="p-toolbar-group-start">
    <button pButton>Export</button>
  </div>
</p-toolbar> -->
