import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgIf, NgOptimizedImage } from "@angular/common";
import { GraphQLError } from "graphql";
import { CurrentUserService } from "../services/current-user.service";
import { CurrentUser } from "../interfaces/current-user.interface";
import { Router, RouterModule } from "@angular/router";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { environment } from "../../environments/environment";
import { AuthTokenService } from '../services/auth-token.service';
import { LegacyMessageService } from "../services/legacy-message.service";
import { Subscription } from "rxjs";

/**
 * HeaderComponent represents the navigation bar component of the application.
 *
 * It is responsible for rendering navigation links based on the user's authorization.
 * It also handles logouts and manages click events from the legacy application.
 */
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgIf,
    RouterModule,
    NgOptimizedImage,
    BsDropdownModule,
    TooltipModule
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})

export class HeaderComponent implements OnInit, OnDestroy {
  currentUser: CurrentUser | null | undefined = null;
  errors: readonly GraphQLError[] | undefined;
  private currentUserSubscription: Subscription = new Subscription();
  private legacyMessageServiceSubscription: Subscription = new Subscription();

  constructor(
    private currentUserService: CurrentUserService,
    private legacyMessageService: LegacyMessageService,
    private router: Router,
    private authTokenService: AuthTokenService
  ) { }

  /**
   * Lifecycle hook performed after the constructor. It initiates two major operations:
   * 1. It subscribes to the CurrentUserService to receive the latest user information.
   * Whenever updated user data is published by the CurrentUserService, it updates the
   * `currentUser` property of this component. - The subscription is stored in the
   * `currentUserSubscription` property for possible cleanup in the `ngOnDestroy` lifecycle hook.
   * 2. Invokes `initLegacyClickEventHandling()`. This method subscribes to the `LegacyMessageService`. Specifically, it is listening for messages of a 'click_data' kind, simulating a click event when such a message is received.
   *
   * Both of these operations are critical for the proper functioning of the HeaderComponent.
   */
  ngOnInit() {
    this.currentUserSubscription = this.currentUserService.currentUser.subscribe(result => {
      this.currentUser = result?.currentUser;
    });
    this.initLegacyClickEventHandling();
  }

  /**
   * This method sets up a subscription to the `currentMessage$` observable of the `LegacyMessageService`.
   * It's triggered during the `ngOnInit` lifecycle hook.
   *
   * Upon receiving a message, the method checks if the message kind is 'click_data'. If it is, `simulateClick()` method
   * is triggered to mimic a click event in the legacy application. This is necessary to close an open menu when
   * there is a click inside the legacy application.
   *
   * The subscription is kept in `legacyMessageServiceSubscription` which can be used to clean up in `ngOnDestroy` when
   * the component is being destroyed to avoid memory leaks.
   */
  initLegacyClickEventHandling() {
    this.legacyMessageServiceSubscription = this.legacyMessageService.currentMessage$.subscribe(
      (data: any) => {
        if (data.kind === 'click_data') {
          this.simulateClick();
        }
      }
    );
  }

  /**
   * Checks if the current user has admin authorization.
   *
   * @returns {boolean} - True if the current user has admin authorization, false otherwise.
   */
  protected checkPermissions(): boolean {
    return <boolean>this.currentUser?.authorization.isAdmin
  }

  /**
   * Retrieves the current user's full name along with their company.
   *
   * @access protected
   *
   * @return {string} The current user's full name and company.
   */
  protected currentUserName(): string {
    return this.currentUser?.firstname + " " + this.currentUser?.lastname + "(" + this.currentUser?.company + ")"
  }

  /**
   * Performs logout action by sending a DELETE request to the legacy app's sign out endpoint.
   * This method also logs out the current user and navigates to the login page.
   * @async
   * @protected
   * @method
   * @returns {Promise<void>} - A promise that resolves once the logout process is complete.
   */
  protected async logout(): Promise<void> {
    let response = await fetch(environment.legacyAppUrl + "/users/sign_out", {
      "headers": {
        "accept": "application/json",
        "content-type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": await this.authTokenService.getAuthenticityToken()
      },
      "method": "DELETE",
      "credentials": 'include'
    });
    this.currentUserService.logout();
    await this.router.navigateByUrl("/login");
  }

  /**
   * Simulates a click event at the document's body section.
   * This is necessary to close an open menu when there is a click
   * inside the legacy application.
   */
  simulateClick() {
    let event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    });

    let target = document.body;
    target.dispatchEvent(event);
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
    this.legacyMessageServiceSubscription.unsubscribe();
  }
}
